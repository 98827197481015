import { computed } from 'vue'


const DEFAULT_COLOR = '#666' // 默认未选中下字体颜色
const DEFAULT_BACKGROUND_COLOR = '#F6F6F6'// 默认未选中下背景颜色

// 激活状态下背景色添加透明度
function addOpacity (color) {
  if (!color || color.startsWith('rgba')) return color

  let r, g, b

  if (color.startsWith('rgb')) {
    [r, g, b] = color.match(/\d+/g)
  } else {
    const hex = color.replace('#', '')

    if (hex.length === 3) {
      r = parseInt(hex.charAt(0).repeat(2), 16)
      g = parseInt(hex.charAt(1).repeat(2), 16)
      b = parseInt(hex.charAt(2).repeat(2), 16)
    } else {
      r = parseInt(hex.substring(0, 2), 16)
      g = parseInt(hex.substring(2, 4), 16)
      b = parseInt(hex.substring(4, 6), 16)
    }
  }

  return `rgba(${r}, ${g}, ${b}, 0.04)`
}

// 标签插坑样式
export function useTagSlotStyles ( item ) {
  return computed(() => { 
    const { active = false, selectedNum = 0, cccConfig = {} } = item.value
    const {
      checkedBgColor = DEFAULT_BACKGROUND_COLOR,
    } = cccConfig?.displayContent || {}
      
    return (active || selectedNum) ? {
      color: checkedBgColor,
      border: `1px solid ${checkedBgColor }`,
      backgroundColor: addOpacity(checkedBgColor),
    } : {
      color: DEFAULT_COLOR,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  })
}

// 文本标签样式
export function useCccTextStyles ( refActive, cccConfig ) {
  return computed(() => { 
    const {
      checkedBgColor = DEFAULT_BACKGROUND_COLOR,
    } = cccConfig?.displayContent || {}
      
    return refActive.value ? {
      color: checkedBgColor,
      backgroundColor: addOpacity(checkedBgColor),
      border: `1px solid ${checkedBgColor }`,
    } : {
      color: DEFAULT_COLOR,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  })
}
 
// 图片标签样式
export function useCccImageStyles (refActive, cccConfig) {
  return computed(() => {
    const {
      checkedBgColor = DEFAULT_BACKGROUND_COLOR,
    } = cccConfig?.displayContent || {}
    
    return refActive.value ? {
      color: checkedBgColor,
      border: `1px solid ${checkedBgColor}`,
      backgroundColor: addOpacity(checkedBgColor),
    } : {
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  })
}

// 图片样式
export function useCccImgData( cccConfig ) {
  return computed(() => {
    const { src, ratio } = cccConfig?.displayContent?.image || {}
    
    return {
      src,
      styles: {
        height: '12px',
        width: `${ratio * 12}px`,
      }
    }
  })
}

// expose
export function useExpose (refScene, refItem) { 
  return computed(() => {
    if (refScene.vlue !== 'flash') return
  
    const data = {
      category_id: refItem.value.value,
    }
  
    return {
      id: '2-17-27',
      code: 'FlashSaleCategoryId',
      prefix: 'FlashSale_Category_Id',
      delayReady: 1500,
      data
    }
  }) 
}

// qs标签固定新样式
export function useQsStyles ( refActive ) {
  return computed(() => { 
    const color = '#198055'
    return refActive.value ? {
      color,
      backgroundColor: addOpacity(color),
      border: `1px solid ${color }`,
    } : {
      color,
      backgroundColor: DEFAULT_BACKGROUND_COLOR,
    }
  })
}
