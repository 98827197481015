<template>
  <div
    v-expose="exposeData"
    class="cloud-tag_ccc-image"
    :style="styles"
    @click="$emit('click')"
  >
    <img
      :src="imgData.src"
      :style="imgData.styles"
    />

    <CloudTagActiveIcon
      v-if="active"
      :color="styles.color"
    />
  </div>
</template>

<script setup>
import { toRef } from 'vue'
import { useExpose, useCccImgData, useCccImageStyles } from './hooks/index.js'

import CloudTagActiveIcon from '../CloudTagActiveIcon.vue'

const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => {}
  },
  scene: {
    type: String,
    default: ''
  },
})

const imgData = useCccImgData(props.item.cccConfig)
const exposeData = useExpose(toRef(props, 'scene'), toRef(props, 'item'))
const styles = useCccImageStyles(toRef(props, 'active'), props.item.cccConfig)
</script>

<style scoped lang="less">
  .cloud-tag_ccc-image {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    min-width: 60px;
    padding: 0 12px;
    margin-right: 8px;
    position: relative;
    height: 27 / 37.5rem;
    line-height: 27 / 37.5rem;
    border: 1px solid #fff;

    img {
      display: block;
      height: 12px;
    }
  }
</style>  
