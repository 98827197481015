<template>
  <div
    v-expose="exposeData"
    class="cloud-tag_ccc-text"
    :style="styles"
    @click="$emit('click')"
  >
    <CloudTagIcon
      v-if="icon"
      :icon="icon"
    />

    <div>{{ label }}</div>

    <CloudTagActiveIcon
      v-if="active"
      :color="styles.color"
    />
  </div>
</template>

<script setup>
import { toRef, computed } from 'vue'

// components
import CloudTagIcon from '../CloudTagIcon.vue'
import CloudTagActiveIcon from '../CloudTagActiveIcon.vue'

// hooks
import { useExpose, useCccTextStyles } from './hooks/index.js'

const props = defineProps({
  active: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => ({})
  },
  scene: {
    type: String,
    default: ''
  },
})

const exposeData = useExpose(toRef(props, 'scene'), toRef(props, 'item'))
const styles = useCccTextStyles(toRef(props, 'active'), props.item.cccConfig)
const icon = computed(() => props.item.cccConfig?.displayContent?.icon?.src || '')
const label = computed(() => props.item.cccConfig?.displayContent?.labelLang || props.item.label || props.item.tag_name || '')

</script>

<style scoped lang="less">
  .cloud-tag_ccc-text {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: auto;
    font-size: 12px;
    padding: 0 12px;
    margin-right: 8px;
    white-space: nowrap;
    height: 27 / 37.5rem;
    line-height: 27 / 37.5rem;
    border: 1px solid #fff;
  }
</style>
